<template>
  <div class="appointmentList-view">
    <div class="flex-a-b-c">
      <div class="flex-c-c">
        <el-input
            v-model="companyName"
            class="mr-16 d-flex align-items-center"
            placeholder="请输入操作内容"
        ></el-input>
        <el-button
            class="v-primary ml10"
            type="primary"
            @click="search"
        >查询</el-button
        >
      </div>
    </div>

    <commonTable
        :tableData="tableData"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
        :currentPage="currentPage"
        :loading="loading"
        :total="total"
    >
      <template v-slot:table>
        <el-table-column prop="loginUserName" align="center" label="操作角色" />
        <el-table-column prop="operationModel" align="center" label="操作模块" />
        <el-table-column prop="operationName" align="center" label="操作内容" />
        <el-table-column prop="createTime" align="center" label="操作时间"/>
      </template>
    </commonTable>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import {queryOperationLog} from "@/api/log.js";
export default {
  components: {
    commonTable,
  },
  data() {
    return {
      companyName:'',
      tableData: [],
      total: 0, //总条数
      currentPage: 1, //当前页数
      pageNum: 1,
      pageSize: 10,
      loading: false, //加载中
    };
  },

  mounted() {
    this.getTableData();
  },
  methods: {
    getTableData(payload) {
      this.loading = true;
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        associationId: localStorage.getItem("associationId"),
        ...payload
      };
      queryOperationLog(data)
          .then((res) => {
            let list = res.data.pageInfo.list
            for (let i in list) {
              list[i].createTime = this.renderTime(list[i].createTime)
            }
            this.tableData = list;
            this.loading = false;
            this.total = res.data.pageInfo.total;
          })
          .catch((err) => {
            this.tableData = [];
            this.loading = false;
          });
    },
    renderTime(date) {
      var dateee = new Date(date).toJSON();
      return new Date(+new Date(dateee) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
    },
    //当前页变更
    handleCurrentChange(num) {
      this.currentPage = num;
      this.getTableData();
    },
    //当前页查询数据条数变更
    handleSizeChange(num) {
      this.pageSize = num;
      this.getTableData();
    },
    search(){
      this.currentPage = 1;
      this.pageNum = 1;
      let data = {
        operationName: this.companyName,
      }
      this.getTableData(data);
    }
  },
};
</script>

<style lang="scss" scoped>
.appointmentList-view {
  background: #ffffff;
  padding: 24px;
}
.customer-manage-dialog {
  .el-dialog {
    width: 450px;

    .el-dialog__header {
      text-align: center;
    }

    .el-form-item {
      margin-bottom: 10px;
    }

    .w240 {
      width: 240px;
    }
  }
}
</style>

